import * as Yup from "yup";
import Page from "../Page";
import {
  RolesEnum,
  SortDirections,
  ThemeColorsEnum,
  OrderStatusIdEnum,
  CustomerColumnsEnum,
  UnitOfMeasurementsEnum,
} from "../../constants/enums";
import Enumerable from "linq";
import { debounce } from "lodash";
import { Col, Row } from "react-bootstrap";
import Schemas from "../../constants/schemas";
import Services from "../../services/services";
import Card from "../../components/cards/Card";
import { useNavigate } from "react-router-dom";
import AppForm from "../../components/forms/Form";
import { AppRoutes } from "../../constants/appRoutes";
import useBranchInfo from "../../hooks/useBranchInfo";
import { WorkOrderTypesEnum } from "../../constants/enums";
import SubmitButton from "../../components/forms/SubmitButton";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import FormTextField from "../../components/forms/FormTextField";
import FormSelectField from "../../components/forms/FormSelectField";
import React, { useContext, useEffect, useMemo, useState } from "react";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import FormCustomerSelect from "../../components/forms/FormCustomerSelect";
import { AuthContext, LoadingContext } from "../../context/contextProvider";
import FormDatePickerField from "../../components/forms/FormDatePickerField";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";
import FormGenericOrderProducts from "../../components/forms/FormGenericOrderProducts";
import FormCustomerLocationSelect from "../../components/forms/FormCustomerLocationSelect";

const days = [
  { name: "Sunday", value: 0 },
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
];

const ScheduledOrderFormPage = ({
  breadcrumbs,
  title,
  initialWorkOrderType,
  scheduledOrder = {},
  order = {},
  onEdit,
  ...otherProps
}) => {
  const [customers, setCustomers] = useState([]);
  const [customerPredictions, setCustomerPredictions] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [products, setProducts] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [enableReinitialize, setEnableReinitialize] = useState(true);

  const [workOrderType, setWorkOrderType] = useState(initialWorkOrderType);

  const { currentClient } = useContext(AuthContext);
  const { busy, setLoading } = useContext(LoadingContext);

  const { isPropaneBranch, isPetroleumBranch} = useBranchInfo();
  const userInfo = useCurrentUserInfo();
  const navigate = useNavigate();

  const initialValues = {
    id: scheduledOrder?.id ?? 0,
    assignedToUserId: order?.assignedToUserId ?? "",
    createOn: Number(scheduledOrder?.scheduleJSON?.createOn) ?? "",
    customerDisplayName: order?.customerDisplayName ?? "",
    customerId: order?.customerId ?? "",
    customerLocationId: order?.customerLocationId ?? "",
    dueDate: scheduledOrder?.dueDate ?? new Date(), //required
    dueOn: Number(scheduledOrder?.scheduleJSON?.dueOn) ?? "",
    instructions: order?.customerPermanentInstructions ?? "",
    isDriverLocked: order?.isDriverLocked ?? false,
    locationName: order?.locationName ?? "",
    notes: scheduledOrder?.notes ?? "",
	assignedToIds: order?.assignedToIds ?? "",
	assignedToNames: order?.assignedToNames ?? "",
    categoryId: order?.categoryId ?? "",
    categoryName: order?.categoryName ?? "",
	title: order?.title ?? "",
    productList:
      (workOrderType !== WorkOrderTypesEnum.serviceOrder ? 
	  (order?.productList ??
      Enumerable.from(products)
        .select((x) => ({
          id: 0,
          productId: x.id,
          quantity: "",
          unitOfMeasurement: UnitOfMeasurementsEnum.liters,
          fill: false,
        }))
        .orderBy((x) => x.order)
        .thenBy((x) => x.shortName)
        .take(4)
        .toArray()) : []),
    // startDate: "2021-10-11T00:00:00",
    startDate: scheduledOrder?.scheduleJSON?.startDate ?? "",
    weeksInterval: scheduledOrder?.scheduleJSON?.weeksInterval ?? "",
  };
  const baseValidationSchema = useMemo(
    () => {
      if (workOrderType === WorkOrderTypesEnum.serviceOrder) {
        return Yup.object().shape({
          ...Schemas.servicesOrOtherOrderShape,
          dueDate: Yup.date().optional(),
          dueOn: Yup.number().required().label("Due on"),
          createOn: Yup.number().required().label("Create on"),
          startDate: Yup.date().required().label("Start Date"),
          weeksInterval: Yup.number().required().label("Interval"),
        });
      }
      else{
      return Yup.object().shape({
        ...(isPropaneBranch && workOrderType !== WorkOrderTypesEnum.serviceOrder ? Schemas.propaneOrderShape
          : isPetroleumBranch && workOrderType !== WorkOrderTypesEnum.serviceOrder ? Schemas.petroleumOrderShape
          : Schemas.petroleumOrderShape),
        dueDate: Yup.date().optional(),
        dueOn: Yup.number().required().label("Due on"),
        createOn: Yup.number().required().label("Create on"),
        startDate: Yup.date().required().label("Start Date"),
        weeksInterval: Yup.number().required().label("Interval"),
      });
    }
  }, [isPropaneBranch, isPetroleumBranch, workOrderType]);

  const [validationSchema, setValidationSchema] =
    useState(baseValidationSchema);

  useEffect(() => {
    initAsync();
  }, [currentClient, workOrderType]); // eslint-disable-line react-hooks/exhaustive-deps


  const getAndSetCustomerAsync = async (id) => {
    setLoading(true);
    let response = await Services.api.customers.get(id);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the customer",
        ThemeColorsEnum.error
      );

	setCustomers([
			{
			  id: id,
			  company: response.data.displayName,
			  instructions: response.data.instructions,
			},
		  ]);
   setLoading(false);

  };

  useEffect(() => {
    if (order?.customerId) 
	{
	  if (!order?.customerDisplayName)
		{
			getAndSetCustomerAsync(scheduledOrder.customerId);
		}
	  else {
		setCustomers([
			{
			  id: order.customerId,
			  company: order.customerDisplayName,
			},
		  ]);
	  }
		    getCustomerLocationsAsync(scheduledOrder.customerId);
    }
  }, [scheduledOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customerLocations?.length > 0) {
      setValidationSchema(
        baseValidationSchema.concat(
          Yup.object({
            customerLocationId: Yup.number()
              .required()
              .label("Customer location")
              .typeError("Customer location is a required field"),
          })
        )
      );
    } else setValidationSchema(baseValidationSchema);
  }, [customerLocations, baseValidationSchema]);


  const onChangeOrderType = async (orderType, {setFieldValue, values}) => {
	  setWorkOrderType(Number(orderType.value));
	  const newProductsList =
		orderType.value !== WorkOrderTypesEnum.serviceOrder ? 
		  (order?.productList && order?.productList?.length > 0 
				? order?.productList
		  : Enumerable.from(products)
			.select((x) => ({
			  id: 0,
			  productId: x.id,
			  quantity: "",
			  unitOfMeasurement: UnitOfMeasurementsEnum.liters,
			}))
			.orderBy((x) => x.order)
			.thenBy((x) => x.shortName)
			.take(4)
			.toArray()) : [];
    setFieldValue("productList", newProductsList);
	order.productList = newProductsList;
	}

  const getCustomerLocationsAsync = async (customerId) => {
	setLoading(true);
    const response = await Services.api.customers.getLocations(customerId);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the locations",
        ThemeColorsEnum.error
      );

    setCustomerLocations(response.data);
	setLoading(false);
    return response.data;
  };

  const getProductsAsync = async () => {
    const response = await Services.api.products.all();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the products",
        ThemeColorsEnum.error
      );

    setProducts(response.data);
  };

  const getUsersAsync = async () => {
    const response = await Services.api.users.getByRole(RolesEnum.driver);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the drivers",
        ThemeColorsEnum.error
      );

    const finalData = Services.utility.getDataForUserPicker(response.data);
    setDrivers(finalData);
  };

  const handleCustomerInputChange = async (text) => {
    if (!text) return setCustomerPredictions(customers);
    text = text.toLowerCase();

    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(text);
    filter.start = 0;
    filter.length = 20;
    filter.order[0].column = CustomerColumnsEnum.company;
    filter.order[0].dir = SortDirections.ascending;

    // setLoading(true);
    const response = await Services.api.customers.getCustomers(filter);
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the customers",
        ThemeColorsEnum.error
      );

    setCustomerPredictions(response.data?.data);
    // const matches = Services.utility.getCustomerPredictions(customers, text);
    // setCustomerPredictions(matches);
  };

  const handleCustomerInputChangeWithDebounce = useMemo(
    () =>
      debounce((text) => {
        handleCustomerInputChange(text?.trim());
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSelectCustomer = async (customer, { setFieldValue, values }) => {
    if (!customer) {
      setCustomerLocations([]);
      setFieldValue("customerLocationId", null);
      return;
    }

    // autofill permanent instructions
    setFieldValue("instructions", customer?.permanentInstructions ?? "");
    setFieldValue("customerDisplayName", customer?.displayName ?? "");

    const locations = await getCustomerLocationsAsync(customer.id);
    if (locations?.length === 1)
      setFieldValue("customerLocationId", locations[0].id);

    if (isPropaneBranch) {
      // need to autofill the tank size
      const productsWithNewTankSize = values["productList"]?.map((product) => ({
        ...product,
        tankSize: customer?.tankSize,
      }));
      setFieldValue("productList", productsWithNewTankSize);
    }
  };

  const separateScheduleAndOrder = (values) => 
	{
		const scheduled_order = {
      id: values?.id,
			ClientId: values.clientId,
			CustomerId: values.customerId,
			CustomerLocationId: values.customerLocationId,
			templateId: values.templateId,
			workOrderType: workOrderType,
      ScheduleJSON: {
        CreateOn: values.createOn,
        DueOn: values.dueOn,
        StartDate: values.startDate,
        WeeksInterval: values.weeksInterval,
      }
		};

		const order = {
			...values,
			Status: "T",
			OrderType: workOrderType,
			IsDirectDrop: workOrderType === WorkOrderTypesEnum.directDrop,
			productsList: values.productList,
			orderStatusId: OrderStatusIdEnum.template,
		};
		return [ scheduled_order, order ];
	};

  const handleSubmit = async (values) => {
    /*
    * First, create order of the correct type.
    * Then, create the schedule, making sure to include the orderId as templateId
    */
    values.status = "T";
    values.clientId = currentClient.id;
	values.orderType = workOrderType;
	if (onEdit !== undefined) {
		const [s_order, editOrder] = separateScheduleAndOrder(values);
		editOrder.Status = "T";
		onEdit(s_order, editOrder);
		return;
	}

    let orderResponse;
    setLoading(true);
    // eslint-disable-next-line default-case
    switch (workOrderType) {
      case WorkOrderTypesEnum.workOrder:
        values.isDirectDrop = false;
        orderResponse = await Services.api.workOrders.save({
            ...values,
          });
        break;
      case WorkOrderTypesEnum.serviceOrder:
        orderResponse = await Services.api.serviceOrders.save({
          ...values,
        });
        break;
      case WorkOrderTypesEnum.directDrop:
        values.isDirectDrop = true;
        orderResponse = await Services.api.workOrders.save({
          ...values,
        });
        break;
    }
    setLoading(false);

    if (!orderResponse?.ok){
      return Services.utility.toast(
        "Could not save the order",
        ThemeColorsEnum.error,
        orderResponse
      )};

    scheduledOrder.templateId = orderResponse.data.id;

    const payload = {
	  Status: "A",
      ...scheduledOrder,
      ...values,
      scheduleJSON: {
        createOn: values.createOn,
        dueOn: values.dueOn,
        startDate: values.startDate,
        weeksInterval: values.weeksInterval,
      },
    };
    setLoading(true);
    const response = await Services.api.scheduledOrders.create(payload);
    setLoading(false);

    if (!response || response.status !== 201) {
      return Services.utility.toast(
        "Could not save the schedule",
        ThemeColorsEnum.error,
        response?.data?.message || "Unknown error"
      );
    }

    Services.utility.toast("Saved", ThemeColorsEnum.success);
    //TODO: navigate back to the correct submenu
    navigate(AppRoutes.scheduledOrders);
  };

  const initAsync = async () => {
    setLoading(true);
    setCustomerPredictions(scheduledOrder.customer || []);
    setCustomerLocations(order.customerLocations || []);
    await getProductsAsync();
    await getUsersAsync();
    setEnableReinitialize(false); // otherwise every time you select a customer it resets the form
    setLoading(false);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card className="mb-5">
        <Card.Body>
          <AppForm
            enableReinitialize={enableReinitialize}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Row>
              <Col lg={4}>
                <FormCustomerSelect
                  allItems={customers}
                  autocomplete
                  hidePlusButton
                  itemLabelProperty="company"
                  items={customerPredictions}
                  itemValueProperty="id"
                  label="Customer"
                  name="customerId"
                  onChange={handleSelectCustomer}
                  onInputChange={handleCustomerInputChangeWithDebounce}
                  placeholder="Search by anything..."
                  required
                  searchable
                />
              </Col>
              <Col lg={4}>
                <FormCustomerLocationSelect
                  hidePlusButton
                  itemLabelProperty="name"
                  items={customerLocations}
                  itemValueProperty="id"
                  label="Customer Location"
                  name="customerLocationId"
                  required={customerLocations?.length > 0}
                  searchable
                />
              </Col>
			{workOrderType != WorkOrderTypesEnum.serviceOrder && (<Col lg={4}>
                <FormSelectField
                  disabled={
                    !userInfo?.isAdmin && scheduledOrder?.isDriverLocked
                  }
                  itemLabelProperty="label"
                  items={drivers}
                  itemValueProperty="value"
                  label="Driver"
                  name="assignedToUserId"
                  searchable
                />
                <FormCheckboxField
                  description="Only Managers can change the driver"
                  name="isDriverLocked"
                />
              </Col>)}
            </Row>

            <Row>
              <Col lg={3}>
              <FormSelectField
                label="Order Type"
                name="workOrderType"
                itemLabelProperty="label"
                itemValueProperty="value"
                items={Object.values(WorkOrderTypesEnum).map(value => ({
                  value: Number(value),
                  label: (() => {
                    switch (Number(value)) {
                      case WorkOrderTypesEnum.workOrder: return "Work Order";
                      case WorkOrderTypesEnum.serviceOrder: return "Service Order";
                      case WorkOrderTypesEnum.directDrop: return "Direct Drop";
                      default: return Number(value);
                    }
                  })()
                }))}
                value={workOrderType === null ? '' : workOrderType}
                onChange={onChangeOrderType}
                initialValue={initialWorkOrderType}
                required
              />
              </Col>
              <Col lg={9}>
                <FormGenericOrderProducts
				  order={order}
                  orderType={workOrderType}
				  onChangeOrderType={onChangeOrderType}
                  availableProducts={products}
                  Label="Products"
                  name="productList"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormTextField
                  autoComplete="on"
                  label="Location Notes"
                  name="locationName"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Hazard Assessment/Permanent Instructions"
                  name="instructions"
                />
              </Col>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="One-time Notes"
                  name="notes"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3}>
                <FormSelectField
                  itemLabelProperty="name"
                  items={days}
                  itemValueProperty="value"
                  label="Create on"
                  name="createOn"
                  required
                  searchable
                />
				   <>
					  Specify which day the order will be created on.
                    </>
              </Col>
			  <Col lg={3}>
                <FormSelectField
                  itemLabelProperty="name"
                  items={days}
                  itemValueProperty="value"
                  label="Due on"
                  name="dueOn"
                  required
                  searchable
                  //value={initialValues.dueOn}
                />
					<>
                      Specify which day the order will be due.
                      <br />
                    </>
              </Col>

              <Col lg={3}>
                <FormTextField
                  label="Schedule Frequency"
                  name="weeksInterval"
                  placeholder="Schedule Frequency"
                  required
                  type="number"
                  min="1"
                  //oninput="this.value = Math.abs(this.value)"
                />
				<>
				Repeat every X weeks
				<br />
				E.g: 1=Weekly, 2=Every two weeks, etc.
				</>

              </Col>
              <Col lg={3}>
                <FormDatePickerField
                  label="Start Date"
                  name="startDate"
                  placeholder="Select a date"
                  required
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ScheduledOrderFormPage;
