import * as Yup from "yup";

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CustomerColumnsEnum,
  OrderStatusIdEnum,
  RolesEnum,
  SortDirections,
  StatusEnum,
  ThemeColorsEnum,
} from "../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import { debounce } from "lodash";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import FormCustomerLocationSelect from "../../components/forms/FormCustomerLocationSelect";
import FormCustomerSelect from "../../components/forms/FormCustomerSelect";
import FormDatePickerField from "../../components/forms/FormDatePickerField";
import FormSelectField from "../../components/forms/FormSelectField";
import FormServiceCategorySelect from "../../components/forms/FormServiceCategorySelect";
import FormTextField from "../../components/forms/FormTextField";
import SubmitButton from "../../components/forms/SubmitButton";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import { AppRoutes } from "../../constants/appRoutes";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";

const baseValidationSchema = Yup.object().shape({
  categoryId: Yup.number()
    .required()
    .label("Category")
    // .typeError("Category is a required field"),
    .typeError("Category must be a number"),
  customerId: Yup.number().optional(),
  dueDate: Yup.date()
    .required()
    .label("Due date")
    .typeError("Due date is a required field"),
});

const ServiceOrderFormPage = ({
  breadcrumbs,
  serviceOrder = {},
  title,
  ...otherProps
}) => {
  const [categories, setCategories] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerPredictions, setCustomerPredictions] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [hideNewLocationButton, setHideNewLocationButton] = useState(
    !serviceOrder?.customerId
  );
  const [validationSchema, setValidationSchema] =
    useState(baseValidationSchema);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: serviceOrder?.customerLatitude,
    longitude: serviceOrder?.customerLongitude,
    name: serviceOrder?.customerLocationName,
  });

  const { busy, setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);
  const { currentClient } = useContext(AuthContext);

  const userInfo = useCurrentUserInfo();

  const navigate = useNavigate();

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (serviceOrder?.customerId > 0) {
      setCustomers([
        {
          id: serviceOrder.customerId,
          company: serviceOrder.customerDisplayName,
        },
      ]);
      setHideNewLocationButton(false);
      getCustomerLocationsAsync(serviceOrder.customerId);
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (customerLocations?.length > 0) {
      setValidationSchema(
        baseValidationSchema.concat(
          Yup.object({
            customerLocationId: Yup.number()
              .required()
              .label("Customer location")
              .typeError("Customer location is a required field"),
          })
        )
      );
    } else setValidationSchema(baseValidationSchema);
  }, [customerLocations]);

  const getCategoriesAsync = async () => {
    const response = await Services.api.serviceCategories.all();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the categories",
        ThemeColorsEnum.error
      );

    setCategories(response.data);
  };

  const getCustomerLocationsAsync = async (customerId) => {
    const response = await Services.api.customers.getLocations(customerId);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the locations",
        ThemeColorsEnum.error
      );

    setCustomerLocations(response.data);

    return response.data;
  };

  const getUsersAsync = async () => {
    const response = await Services.api.users.getByRole(RolesEnum.serviceTech);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the users",
        ThemeColorsEnum.error
      );

    const finalData = Services.utility.getDataForUserPicker(response.data);
    setTechnicians(finalData);
  };

  const handleCustomerInputChange = async (text) => {
    if (!text) return setCustomerPredictions(customers);
    text = text.toLowerCase();

    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(text);
    filter.start = 0;
    filter.length = 20;
    filter.order[0].column = CustomerColumnsEnum.company;
    filter.order[0].dir = SortDirections.ascending;

    // setLoading(true);
    const response = await Services.api.customers.getCustomers(filter);
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the customers",
        ThemeColorsEnum.error
      );

    setCustomerPredictions(response.data?.data);
    // const matches = Services.utility.getCustomerPredictions(customers, text);
    // setCustomerPredictions(matches);
  };

  const handleCustomerInputChangeWithDebounce = useMemo(
    () =>
      debounce((text) => {
        handleCustomerInputChange(text?.trim());
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSelectCategory = (category, { setFieldValue }) => {
    // autofill description
    setFieldValue("description", category?.notes);
  };

  const handleSelectCustomerLocation = async (customer_location) => {
    if (customer_location) {
        setSelectedLocation(customer_location); 
    }
  };

  const handleSelectCustomer = async (customer, { setFieldValue, values }) => {
    if (!customer) {
      setCustomerLocations([]);
      setFieldValue("customerDisplayName", "");
      setFieldValue("customerLocationId", null);
      setHideNewLocationButton(true);
      setSelectedLocation(null);
      return;
    }

    setHideNewLocationButton(false);

    const locations = await getCustomerLocationsAsync(customer.id);
    if (locations?.length === 1) {
      setFieldValue("customerLocationId", locations[0].id);
      setSelectedLocation(locations[0]);
    }

    setFieldValue("customerDisplayName", customer?.displayName);
  };

  const handleSubmit = async (values) => {
    if (values.orderStatusId !== OrderStatusIdEnum.completed) {
      setLoading(true);
      const response = await Services.api.serviceOrders.isDuplicate(values);
      setLoading(false);

      if (response.data === true) {
        return showConfirmation(
          "Confirmation",
          `There is already an order for ${values["customerDisplayName"]} on the same day.\n\nDo you still want to save the order?`,
          [
            {
              color: ThemeColorsEnum.quaternary,
              title: "No",
            },
            {
              title: "Yes",
              onClick: async () => await submitAsync(values),
            },
          ]
        );
      }
    }

    await submitAsync(values);
  };

  const initAsync = async () => {
    setLoading(true);
    setCustomerPredictions([]);
    setCustomerLocations([]);
    await getCategoriesAsync();
    await getUsersAsync();
    if (serviceOrder?.customerId > 0) {
        await getCustomerLocationsAsync(serviceOrder.customerId);
    }
    setLoading(false);
  };

  const submitAsync = async (values) => {
    setLoading(true);
    const response = await Services.api.serviceOrders.save({
      ...serviceOrder,
      ...values,
    });
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the order",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Saved", ThemeColorsEnum.success);

    const returnPage =
      response.data?.status === StatusEnum.completed
        ? AppRoutes.serviceOrders_completed
        : AppRoutes.serviceOrders;
    navigate(returnPage);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={{
              id: serviceOrder?.id ?? 0,
              assignedToIds: serviceOrder?.assignedToIds ?? "",
              categoryId: serviceOrder?.categoryId,
              customerDisplayName: serviceOrder?.customerDisplayName ?? "",
              customerLocationId: serviceOrder?.customerLocationId ?? "",
              customerId: serviceOrder?.customerId,
              description: serviceOrder?.description ?? "",
              dueDate: serviceOrder?.dueDate ?? "",
              isTechLocked: serviceOrder?.isTechLocked,
              isHighPriority: serviceOrder?.isHighPriority,
              notes: serviceOrder?.notes ?? "",
              orderStatusId: serviceOrder?.orderStatusId,
              title: serviceOrder?.title ?? "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Row>
              <Col lg={3}>
                <FormCustomerSelect
                  allItems={customers}
                  autocomplete
                  itemLabelProperty="company"
                  items={customerPredictions}
                  itemValueProperty="id"
                  label="Customer"
                  name="customerId"
                  onChange={handleSelectCustomer}
                  onInputChange={handleCustomerInputChangeWithDebounce}
                  placeholder="Search by anything..."
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormCustomerLocationSelect
                  hidePlusButton={hideNewLocationButton}
                  itemLabelProperty="name"
                  items={customerLocations}
                  itemValueProperty="id"
                  label="Customer Location"
                  name="customerLocationId"
                  onChange={handleSelectCustomerLocation}
                  required={customerLocations?.length > 0}
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormServiceCategorySelect
                  itemLabelProperty="name"
                  items={categories}
                  itemValueProperty="id"
                  label="Category"
                  name="categoryId"
                  onChange={handleSelectCategory}
                  placeholder="Pick a category..."
                  required
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormDatePickerField
                  // icon="fa-solid fa-calendar-days"
                  label="Due Date"
                  name="dueDate"
                  placeholder="Select a date"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Map
                  centerPosition={{
                    lat: selectedLocation?.latitude,
                    lng: selectedLocation?.longitude,
                  }}
                  className="map-container-modal mb-3"
                  zoom={7}
                >
                  <AppMarker
                    clickable
                    info={
                      <span className="info-window-text-title">
                        {selectedLocation?.name ?? "Customer Location"}
                      </span>
                    }
                    order={serviceOrder}
                    position={{
                      lat: selectedLocation?.latitude,
                      lng: selectedLocation?.longitude,
                    }}
                  />
                </Map>
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormTextField label="Title" name="title" />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  disabled={!userInfo?.isAdmin && serviceOrder?.isTechLocked}
                  itemLabelProperty="label"
                  items={technicians}
                  itemValueProperty="value"
                  multi
                  label="Technician(s)"
                  name="assignedToIds"
                />
                <FormCheckboxField
                  description="Only Managers can change the technician(s)"
                  name="isDriverLocked"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Description"
                  name="description"
                />
              </Col>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="One-time Notes"
                  name="notes"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormCheckboxField
                  description="This is a high priority order"
                  label="High Priority"
                  name="isHighPriority"
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ServiceOrderFormPage;
