import { AuthContext, LoadingContext } from "../../context/contextProvider";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { Navigate } from "react-router-dom";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import WorkOrderFormPage from "../workOrders/WorkOrderFormPage";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: LocalGasStationIcon,
    title: "Delivery Orders",
    to: AppRoutes.deliveryOrders,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const DeliveryOrderEditPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();

  const [workOrder, setWorkOrder] = useState(state?.workOrder);
  const { setLoading } = useContext(LoadingContext);
  const { branchModules } = useContext(AuthContext);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setWorkOrder(state?.workOrder);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && !state?.workOrder) getWorkOrderAsync(id);
	console.log("calling getWorkORders");
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getWorkOrderAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.workOrders.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the order",
        ThemeColorsEnum.error
      );

    setWorkOrder(response.data);
  };

  if (!branchModules?.workOrders || !userInfo.canEditDeliveryOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <WorkOrderFormPage
      breadcrumbs={breadcrumbs}
      title="Delivery Orders - Edit"
      workOrder={workOrder}
    />
  );
};

export default DeliveryOrderEditPage;
