import { AuthContext, LoadingContext } from "../context/contextProvider";
import { Col, Row } from "react-bootstrap";
import {
  DashboardTypesEnum,
  StatusEnum,
  ThemeColorsEnum,
} from "../constants/enums";
import React, { useContext, useEffect, useState } from "react";

import { AppRoutes } from "../constants/appRoutes";
import BuildIcon from "@mui/icons-material/Build";
import Schedule from "@mui/icons-material/Schedule";
import Button from "../components/buttons/Button";
import CalendarViewDayTwoToneIcon from "@mui/icons-material/CalendarViewDayTwoTone";
import DashboardCard from "../components/cards/DashboardCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { LocalStorageKeys } from "../constants/storageKeys";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import Page from "./Page";
import ServiceOrderFilterRequest from "../models/serviceOrderFilterRequest";
import Services from "../services/services";
import WorkOrderFilterRequest from "../models/workOrderFilterRequest";
import dayjs from "dayjs";
import { toast as toastify } from "react-toastify";
import { useNavigate } from "react-router-dom";

const title = "Dashboard";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
  },
];

const DashboardPage = () => {
  const navigate = useNavigate();
  const [summary, setSummary] = useState();

  const { setLoading } = useContext(LoadingContext);
  const { branchModules, currentClient, userSettings } =
    useContext(AuthContext);

  const [params] = useState(new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  }));
  


  useEffect(() => {
      const value = params.ecode;
      console.log("found param in URL: ", value);
      if (value == 403) {
        Services.utility.toast("Intercepted permission error, please check you have the right modules enabled and/or access permissions for this branch.", ThemeColorsEnum.error);
      }
     
  }, [params]);


  useEffect(() => {
    getDashboardSummaryAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDashboardSummaryAsync = async () => {
    setLoading(true);
    const response = await Services.api.home.getSummary();
    setSummary(response.data);
    setLoading(false);
  };

  const handleCompletedServiceOrdersTodayClick = () => {
    //filter
    const filter = new ServiceOrderFilterRequest();

    filter.status = StatusEnum.completed;
    filter.completedOnFrom = dayjs(new Date()).format("YYYY-MM-DD");
    filter.completedOnTo = dayjs(new Date()).format("YYYY-MM-DD");

    //navigate
    navigate(AppRoutes.serviceOrders_completed, {
      state: { filter: filter },
    });
  };

  const handleCompletedWorkOrdersTodayClick = (directDrop = false) => {
    //filter
    const filter = new WorkOrderFilterRequest();

    filter.isDirectDrop = directDrop;
    filter.status = StatusEnum.completed;
    filter.completedOnFrom = dayjs(new Date()).format("YYYY-MM-DD");
    filter.completedOnTo = dayjs(new Date()).format("YYYY-MM-DD");

    //navigate
    navigate(
      directDrop
        ? AppRoutes.directDrops_completed
        : AppRoutes.deliveryOrders_completed,
      {
        state: { filter: filter },
      }
    );
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <h2 className="mt-0 mb-0 hidden-mobile">{title}</h2>
      <p>Here's a quick summary of all your orders.</p>

      <Row>
        {/* Delivery orders - quick links */}
        {branchModules?.workOrders && !userSettings?.hideWorkOrders && (
          <Col xl={3} lg={4} md={6} sm={12}>
            <Row>
              <Col lg={12}>
                <h3>Deliveries</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  IconComponent={LocalGasStationIcon}
                  title="New Delivery Order"
                  to={AppRoutes.deliveryOrders_create}
                />
              </Col>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  IconComponent={CalendarViewDayTwoToneIcon}
                  title="View Delivery Orders"
                  to={AppRoutes.deliveryOrders}
                />
              </Col>
              
            </Row>
          </Col>
        )}

        {/* Direct drops - quick links */}
        {branchModules?.directDrops && !userSettings?.hideDirectDrops && (
          <Col xl={3} lg={4} md={6} sm={12}>
            <Row>
              <Col lg={12}>
                <h3>Direct Drops</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  color={ThemeColorsEnum.tertiary}
                  IconComponent={OilBarrelIcon}
                  title="New Direct Drop"
                  to={AppRoutes.directDrops_create}
                />
              </Col>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  color={ThemeColorsEnum.tertiary}
                  IconComponent={CalendarViewDayTwoToneIcon}
                  title="View Direct Drops"
                  to={AppRoutes.directDrops}
                />
              </Col>
            </Row>
          </Col>
        )}

        {/* Service orders - quick links */}
        {branchModules?.serviceOrders && !userSettings?.hideServiceOrders && (
          <Col xl={3} lg={4} md={6} sm={12}>
            <Row>
              <Col lg={12}>
                <h3>Service Orders</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  color={ThemeColorsEnum.secondary}
                  IconComponent={BuildIcon}
                  title="New Service Order"
                  to={AppRoutes.serviceOrders_create}
                />
              </Col>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  color={ThemeColorsEnum.secondary}
                  IconComponent={CalendarViewDayTwoToneIcon}
                  title="View Service Orders"
                  to={AppRoutes.serviceOrders}
                />
              </Col>
            </Row>
          </Col>
        )}
		{/* Scheduled orders - quick links */}
        {branchModules?.scheduledOrders && !userSettings?.hideScheduledOrders && (
          <Col xl={3} lg={4} md={6} sm={12}>
            <Row>
              <Col lg={12}>
                <h3>Scheduled Orders</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  color={ThemeColorsEnum.quinary}
                  IconComponent={Schedule}
                  title="New Scheduled Order"
                  to={AppRoutes.scheduledOrders_create}
                />
              </Col>
              <Col lg={12}>
                <Button
                  className="btn-block btn-lg"
                  color={ThemeColorsEnum.quinary}
                  IconComponent={CalendarViewDayTwoToneIcon}
                  title="View Scheduled Orders"
                  to={AppRoutes.scheduledOrders}
                />
              </Col>
            </Row>
          </Col>
        )}

      </Row>

      {/* Delivery orders - cards */}
      {branchModules?.workOrders && !userSettings?.hideWorkOrders && (
        <>
          <h3>Delivery Orders</h3>
          <Row>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.quaternary}
                footerText="Delivery orders assigned to me."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.deliveryOrders, {
                    state: { dashboardType: DashboardTypesEnum.myWorkOrders },
                  })
                }
                ordersCount={summary?.myOrdersCount}
                title="My Delivery Orders"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.unassigned}
                footerText="Unassigned delivery orders."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.deliveryOrders, {
                    state: { dashboardType: DashboardTypesEnum.unassigned },
                  })
                }
                ordersCount={summary?.unassignedOrdersCount}
                title="Unassigned"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.due}
                footerText="Due in the next 2 days."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.deliveryOrders, {
                    state: { dashboardType: DashboardTypesEnum.due },
                  })
                }
                ordersCount={summary?.dueOrdersCount}
                title="Due"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.overdue}
                footerText="Due today or overdue."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.deliveryOrders, {
                    state: { dashboardType: DashboardTypesEnum.overdue },
                  })
                }
                ordersCount={summary?.overdueOrdersCount}
                title="Overdue"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.completed}
                footerText={`Completed today ${dayjs().format("MMM D")}.`}
                icon="fa-solid fa-gas-pump"
                onClick={() => handleCompletedWorkOrdersTodayClick(false)}
                ordersCount={summary?.completedOrdersCount}
                title="Completed Today"
              />
            </Col>
          </Row>
        </>
      )}

      {/* Direct drops - cards */}
      {branchModules?.directDrops && !userSettings?.hideDirectDrops && (
        <>
          <h3>DirectDrops</h3>
          <Row>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.quaternary}
                footerText="Direct drops assigned to me."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.directDrops, {
                    state: { dashboardType: DashboardTypesEnum.myWorkOrders },
                  })
                }
                ordersCount={summary?.myDirectDropsCount}
                title="My Direct Drops"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.unassigned}
                footerText="Unassigned delivery orders."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.directDrops, {
                    state: { dashboardType: DashboardTypesEnum.unassigned },
                  })
                }
                ordersCount={summary?.unassignedDirectDropsCount}
                title="Unassigned"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.due}
                footerText="Due in the next 2 days."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.directDrops, {
                    state: { dashboardType: DashboardTypesEnum.due },
                  })
                }
                ordersCount={summary?.dueDirectDropsCount}
                title="Due"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.overdue}
                footerText="Due today or overdue."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.directDrops, {
                    state: { dashboardType: DashboardTypesEnum.overdue },
                  })
                }
                ordersCount={summary?.overdueDirectDropsCount}
                title="Overdue"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.completed}
                footerText={`Completed today ${dayjs().format("MMM D")}.`}
                icon="fa-solid fa-gas-pump"
                onClick={() => handleCompletedWorkOrdersTodayClick(true)}
                ordersCount={summary?.completedDirectDropsCount}
                title="Completed Today"
              />
            </Col>
          </Row>
        </>
      )}

      {/* Service orders - cards */}
      {branchModules?.serviceOrders && !userSettings?.hideServiceOrders && (
        <>
          <h3>Service Orders</h3>
          <Row>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.quaternary}
                footerText="Service orders assigned to me."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.serviceOrders, {
                    state: {
                      dashboardType: DashboardTypesEnum.myServiceOrders,
                    },
                  })
                }
                ordersCount={summary?.myServiceOrdersCount}
                title="My Service Orders"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.unassigned}
                footerText="Unassigned delivery orders."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.serviceOrders, {
                    state: {
                      dashboardType: DashboardTypesEnum.unassignedServiceOrders,
                    },
                  })
                }
                ordersCount={summary?.unassignedServiceOrdersCount}
                title="Unassigned"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.due}
                footerText="Due in the next 2 days."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.serviceOrders, {
                    state: {
                      dashboardType: DashboardTypesEnum.dueServiceOrders,
                    },
                  })
                }
                ordersCount={summary?.dueServiceOrdersCount}
                title="Due"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="mb-4"
                color={ThemeColorsEnum.overdue}
                footerText="Due today or overdue."
                icon="fa-solid fa-gas-pump"
                onClick={() =>
                  navigate(AppRoutes.serviceOrders, {
                    state: {
                      dashboardType: DashboardTypesEnum.overdueServiceOrders,
                    },
                  })
                }
                ordersCount={summary?.overdueServiceOrdersCount}
                title="Overdue"
              />
            </Col>
            <Col xl={3} lg={6}>
              <DashboardCard
                className="dashboard"
                color={ThemeColorsEnum.completed}
                footerText={`Completed today ${dayjs().format("MMM D")}.`}
                icon="fa-solid fa-gas-pump"
                onClick={handleCompletedServiceOrdersTodayClick}
                ordersCount={summary?.completedServiceOrdersCount}
                title="Completed Today"
              />
            </Col>
          </Row>
        </>
      )}
    </Page>
  );
};

export default DashboardPage;
