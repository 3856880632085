import { useContext, useMemo } from "react";
import { CustomerColumnsEnum, ThemeColorsEnum } from "../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../context/contextProvider";

import { useNavigate } from "react-router-dom";
import DropdownSelect from "../components/forms/DropdownSelect";
import { AppRoutes } from "../constants/appRoutes";
import Services from "../services/services";
import useBranchInfo from "./useBranchInfo";
import useCurrentUserInfo from "./useCurrentUserInfo";

const useCustomersDataTable = (onDeleted = () => {}, onSaved = () => {}) => {
  const navigate = useNavigate();
  const userInfo = useCurrentUserInfo();
  const { isPetroleumBranch, isPropaneBranch } = useBranchInfo();

  const { setLoading } = useContext(LoadingContext);
  const { branchModules, userSettings } = useContext(AuthContext);
  const { showConfirmation } = useContext(ModalContext);

  const handleDelete = async (customer) => {
    setLoading(true);
    const response = await Services.api.customers.delete(customer.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the customer",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Customer deleted", ThemeColorsEnum.success);
    onDeleted(customer);
  };

  const menuItems = (customer) => [
    ...(userInfo?.canEditCustomers
      ? [
          {
            title: "Edit",
            onClick: () =>
              navigate(AppRoutes.customers_edit.replace(":id", customer.id), {
                state: { customer: customer },
              }),
          },
          {
            title: "Manage Locations",
            onClick: () =>
              navigate(AppRoutes.customerLocations.replace(":id", customer.id)),
          },
          { type: "divider" }
        ]
      : []),
    ...(branchModules?.workOrders &&
    !userSettings?.hideWorkOrders &&
    userInfo?.canCreateDeliveryOrders
      ? [
          {
            title: "New delivery order",
            onClick: async () => {
			  let customerLocations = [];
			  const response = await Services.api.customers.getLocations(customer.id);
			  if (response.ok) 
				customerLocations = response.data;
              let products = [];

              if (isPropaneBranch) {
                const response = await Services.api.products.all();
                if (response.ok) products = response.data;
              }

              return navigate(AppRoutes.deliveryOrders_create, {
                state: {
                  workOrder: {
                    customerId: customer.id,
                    customerDisplayName: customer.displayName,
                    productList: isPropaneBranch
                      ? [
                          {
                            id: 0,
                            tankSize: customer.tankSize,
                            productId: products ? products[0]?.id : null,
                          },
                        ]
                      : null,
                  },
				  initialCustomerLocations: customerLocations,
                },
              });
            },
          },
        ]
      : []),
    ...(branchModules?.serviceOrders && !userSettings?.hideServiceOrders
      ? [
          {
            title: "New service order",
            onClick: () =>
              navigate(AppRoutes.serviceOrders_create, {
                state: {
                  serviceOrder: {
                    customerId: customer.id,
                    customerDisplayName: customer.displayName,
                  },
                },
              }),
          },
        ]
      : []), // ...(userInfo?.isAdmin
    //   ? [
    //       {
    //         title: "Tattle devices",
    //         onClick: () =>
    //           navigate(AppRoutes.tattleDevices, {
    //             state: {
    //               customer: customer,
    //             },
    //           }),
    //       },
    //     ]
    //   : []),
    ...(userInfo?.canDeleteCustomers
      ? [
          { type: "divider" },
          // Delete
          {
            className: "text-danger",
            onClick: () => {
              showConfirmation(
                "Confirmation",
                "Do you really want to delete this customer?",
                [
                  {
                    color: ThemeColorsEnum.quaternary,
                    title: "Cancel",
                  },
                  {
                    color: ThemeColorsEnum.danger,
                    onClick: () => handleDelete(customer),
                    title: "Delete",
                  },
                ]
              );
            },
            title: "Delete",
          },
        ]
      : []),
  ];

  const columns = useMemo(
    () => [
      {
        header: {
          className: "col-menu",
          id: CustomerColumnsEnum.actionButton,
          name: "id",
        },
        cell: (row) => (
          <DropdownSelect
            color={ThemeColorsEnum.quaternary}
            fixedTitle
            items={menuItems(row)}
            itemLabelProperty="title"
            itemValueProperty="title"
            size="sm"
            style={{ width: "auto" }}
            title={<i className="fa fa-bars" />}
            // title={<MenuIcon />}
          />
        ),
        dataTitle: "Menu",
        className: "nowrap",
      },
      {
        header: {
          id: CustomerColumnsEnum.company,
          name: "company",
          text: "Name",
          sortable: true,
        },
        cell: (row) => row.company,
        dataTitle: "Name",
        className: "max-width-100",
      },
      ...(isPetroleumBranch
        ? [
            {
              header: {
                id: CustomerColumnsEnum.contact,
                name: "contactName",
                text: "Contact",
                sortable: true,
              },
              cell: (row) => row.contactName,
              dataTitle: "Contact",
              className: "nowrap",
            },
          ]
        : []),
      {
        header: {
          id: CustomerColumnsEnum.memberNumber,
          name: "memberNumber",
          text: "Member #",
          sortable: true,
        },
        cell: (row) => row.memberNumber,
        dataTitle: "Member #",
        className: "max-width-80",
      },
      ...(isPetroleumBranch
        ? [
            {
              header: {
                id: CustomerColumnsEnum.phone,
                name: "phone",
                text: "Phone",
                sortable: true,
              },
              cell: (row) => row.phone,
              dataTitle: "Phone",
              className: "nowrap",
            },
            {
              header: {
                id: CustomerColumnsEnum.email,
                name: "email",
                text: "Email",
                sortable: true,
              },
              cell: (row) => row.email,
              dataTitle: "Email",
              className: "nowrap",
            },
            {
              header: {
                id: CustomerColumnsEnum.city,
                name: "city",
                text: "City",
                sortable: true,
              },
              cell: (row) => row.city,
              dataTitle: "City",
              className: "nowrap",
            },
          ]
        : []),
      ...(isPropaneBranch
        ? [
            {
              header: {
                id: CustomerColumnsEnum.serialNumber,
                name: "serialNumber",
                text: "Serial #",
                sortable: true,
              },
              cell: (row) => row.serialNumber,
              dataTitle: "Serial #",
              className: "max-width-80",
            },
            {
              header: {
                id: CustomerColumnsEnum.tankSize,
                name: "tankSize",
                text: "Tank",
                sortable: true,
              },
              cell: (row) => row.tankSize,
              dataTitle: "Tank Size",
              className: "max-width-80",
            },
            {
              header: {
                id: CustomerColumnsEnum.route,
                name: "route",
                text: "Route",
                sortable: true,
              },
              cell: (row) => row.route,
              dataTitle: "Route",
              className: "max-width-80",
            },
            {
              header: {
                id: CustomerColumnsEnum.comment1,
                name: "comment1",
                text: "Comment 1",
                sortable: true,
              },
              cell: (row) => row.comment1,
              dataTitle: "Comment 1",
              className: "max-width-100",
            },
            {
              header: {
                id: CustomerColumnsEnum.comment2,
                name: "comment2",
                text: "Comment 2",
                sortable: true,
              },
              cell: (row) => row.comment2,
              dataTitle: "Comment 2",
              className: "max-width-100",
            },
          ]
        : []),
    ],
    [isPetroleumBranch, isPropaneBranch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { columns };
};

export default useCustomersDataTable;
