import Endpoints from "../../constants/endpoints";
import httpService from "../httpService";
import localStorageService from "../localStorageService";
import { LocalStorageKeys } from "../../constants/storageKeys";

const filterScheduledOrders = async (filter) => {
	const clientId = localStorageService.getProperty(
		LocalStorageKeys.currentClient
	)?.id;
	const url = Endpoints.scheduledOrders.filter.replace("{clientId}", clientId);
	const response = await httpService.post(url, filter);

	return response;
};

const get = async (id) => { 
	const url = Endpoints.scheduledOrders.get.replace("{id}", id);
	const response = await httpService.get(url);

	return response;
};

const deleteOrder = async (id) => {
	const url = Endpoints.scheduledOrders.delete.replace("{id}", id);
	const response = await httpService.delete(url);
	return response;
};

const update = async (payload, sync) => {
	const url = Endpoints.scheduledOrders.update
		.replace("{id}", payload?.id)
		.replace("{sync}", sync);
	const response = await httpService.put(url, payload);
	return response;
};


const create = async (payload) => {
	try {
		const response = await httpService.post(Endpoints.scheduledOrders.create, payload);
		return {
			ok: response.status === 201,
			status: response.status,
			data: response.data,
		};
	} catch (error) {
		console.error("Error creating scheduled order:", error);
		return {
			ok: false,
			status: error.response?.status || 500,
			data: error.response?.data || { message: "An unknown error occurred" },
		};
	}
};

const checkWorkOrderSchedules = async () => {
	try {
		const response = await httpService.get(Endpoints.scheduledOrders.checkWorkOrderSchedules);
		console.log("respoimnse: ", response.data);
		return {
			ok: response.status === 201,
			status: response.status,
			data: response.data,
		};
	}
	catch (error) {
		console.error("Error checking order schedules, ", error);
		return {
			ok: false, 
			status: error.response?.status || 500,
			data: error.response?.data || {message: "Unknown error has ocurred" },
			};
		}
};



const scheduledOrdersApiService = {
	filterScheduledOrders,
	get,
	deleteOrder,
	update,
	create,
	checkWorkOrderSchedules,
};

export default scheduledOrdersApiService;

