import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import { OrderStatusIdEnum } from "../../constants/enums";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const assign = async (id, userId) => {
  const url = Endpoints.serviceOrders.assign
    .replace("{id}", id)
    .replace("{userId}", userId);
  const response = await httpService.put(url);

  return response;
};

const complete = async (payload, sync = false) => {
  const url = Endpoints.serviceOrders.complete
    .replace("{id}", payload?.id)
    .replace("{sync}", sync);
  const response = await httpService.put(url, payload);

  return response;
};

const create = async (payload, sync) => {
  const url = Endpoints.serviceOrders.create.replace("{sync}", sync);
  const response = await httpService.post(url, payload);

  return response;
};

/**
 *
 * @param {int} id The workOrder id
 * @returns
 */
const deleteServiceOrder = async (id) => {
  const url = Endpoints.serviceOrders.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const get = async (id) => {
  const url = Endpoints.serviceOrders.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const getActiveServiceOrdersGeolocations = async (categoryIds = null) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;

  let url = Endpoints.serviceOrders.geolocations.replace(
    "{clientId}",
    clientId
  );

  if (categoryIds)
    url = `${url}${categoryIds?.map((x) => `&categoryIds=${x}`).join("")}`;

  const response = await httpService.get(url);

  return response;
};

const getServiceOrders = async (filter) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = `${Endpoints.serviceOrders.filter.replace(
    "{clientId}",
    clientId
  )}&${filter?.status}`; // add the status to save different cache for active and completed
  const response = await httpService.post(url, filter, false, true);

  return response;
};

const isDuplicate = async (serviceOrder) => {
  // const formattedDueDate = format(new Date(workOrder?.dueDate), "yyyy-MM-dd");
  // const formattedDueDate = moment(workOrder?.dueDate).format("YYYY-MM-DD");
  // const formattedDueDate = utilityService.formatISODate(workOrder?.dueDate);

  const url = Endpoints.serviceOrders.isDuplicate
    .replace("{serviceOrderId}", serviceOrder?.id)
    .replace("{customerId}", serviceOrder?.customerId)
    .replace("{dueDate}", serviceOrder?.dueDate);
  const response = await httpService.get(url);

  return response;
};

const move = async (id, newClientId) => {
  const url = Endpoints.serviceOrders.move
    .replace("{id}", id)
    .replace("{newClientId}", newClientId);
  const response = await httpService.put(url);

  return response;
};

const save = async (payload, sync = false) => {
  console.log("saving service order payload: ", payload);
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  payload = {
    ...payload,
    clientId: payload.clientId ?? clientId,
    // dueDate: utilityService.formatISODate(payload?.dueDate),
    orderStatusId:
      payload?.id > 0 ? payload?.orderStatusId : OrderStatusIdEnum.new,
  };

  return payload?.id > 0
    ? await update(payload, sync)
    : await create(payload, sync);
};

const start = async (id, sync = false) => {
  const url = Endpoints.serviceOrders.start
    .replace("{id}", id)
    .replace("{sync}", sync);
  const response = await httpService.put(url);

  return response;
};

const undoStart = async (id, sync = false) => {
  const url = Endpoints.serviceOrders.undoStart
    .replace("{id}", id)
    .replace("{sync}", sync);
  const response = await httpService.put(url);

  return response;
};

const update = async (payload, sync) => {
  const url = Endpoints.serviceOrders.update
    .replace("{id}", payload?.id)
    .replace("{sync}", sync);
  const response = await httpService.put(url, payload);

  return response;
};

//-----------------------------------------//
const serviceOrdersApiService = {
  assign,
  complete,
  delete: deleteServiceOrder,
  get,
  getActiveServiceOrdersGeolocations,
  getServiceOrders,
  isDuplicate,
  move,
  save,
  start,
  undoStart,
};

export default serviceOrdersApiService;
//-----------------------------------------//
